import React from 'react'
import './Hiring.css';
import { Link } from 'react-router-dom';
import logo from './assets/pics/logo2x.png';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Row,
  Col
} from 'reactstrap';

class Navbars extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <Navbar color="dark" dark expand="md" sticky="top">
        <NavbarBrand href="#" className='trans'>
          <img className="fading" alt='logo' src={logo} style={{ height: 49, width: 216 }} />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink>
                <Link to="/" className="linking" >Home</Link>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

class Hiring extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Navbars />
        <div className="Hiring">
          <h1 style={{ 'textAlign': 'center', 'margin': '5%' }}>Join Us</h1>
          <div className="Something">

            <form id="application" action="https://formspree.io/amyang8877@yahoo.com" method="POST">

              <label>First Name</label>
              <input id="firstName" name="firstName" type="text" />

              <label>Last Name</label>
              <input id="lastName" name="lastName" type="text" />

              <label>Email Address</label>
              <input id="email" name="email" type="email" />

              <label>Phone Number</label>
              <input id="phoneNumber" name="phoneNumber" type="tel" />

              <label>Tell us about yourself</label>
              <textarea id="applicantBio" name="applicantBio" cols="35" wrap="soft"></textarea>

              <div className="buttonHolder">
                <input type="submit" value="Submit Form" />
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default Hiring
