import React from 'react'

const Notfound = () => (
  <div style={{
    top:'50%', left:'50%', 'position': 'absolute', 'transform':'translate(-50%, -50%)',
    textAlign: 'center'
    }}>
    <h1>Page Does Not Exist</h1>
    <h4>
      Were you looking for <a href="/">this</a>?
    </h4>
  </div>
)

export default Notfound
