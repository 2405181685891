import React from 'react';
import logo from './assets/pics/logo2x.png';
import profile from './assets/pics/profile2x.png';
import one from './assets/pics/8.jpg';
import two from './assets/pics/2.jpg';
import three from './assets/pics/3.jpg';
import menu from './assets/pdf/menu.pdf';
import cheatSheet from './assets/pdf/cheatSheet.pdf';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { render } from 'react-dom';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import {
  Element,
  scroller
} from 'react-scroll'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Row,
  Col
} from 'reactstrap';
import {
  faShoppingCart,
  faUtensils,
  faUsers
} from '@fortawesome/free-solid-svg-icons';



class Navbars extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.handleLogoClick = this.handleLogoClick.bind(this);
    this.state = {
      isOpen: false,
      isDark: false
    };
  }
  toggle() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }), () => {
      if(this.state.isOpen){
        document.addEventListener('click', this.handleDocumentClick);
      }else{
        document.removeEventListener('click', this.handleDocumentClick);
      }
    });
  }

  handleLogoClick() {
    const logo = document.querySelector('.fading');
    if (logo) {
      logo.style.opacity = '0.5';
      logo.style.transition = 'opacity .3s ease-in-out';
    }

    setTimeout(() => {
      if (logo) {
        logo.style.opacity = '1';
      }
    }, 500);
  }

  handleDocumentClick(e) {
    if(this.node && this.node.contains(e.target)){
      return;
    }
    this.setState({isOpen: false});
    document.removeEventListener('click', this.handleDocumentClick);
  }

  scrollTo(element) {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  render() {
    return (
      <Navbar color="dark" dark expand="md" sticky="top">
        <NavbarBrand href="#" className='trans' onClick={() => {this.scrollTo('top'); this.handleLogoClick()}}>
          <img className="fading" alt='logo' src={logo} style={{ height: 49, width: 216 }} />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="#" className="linking" onClick={() => this.scrollTo('Values')}>Values</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" className="linking" onClick={() => this.scrollTo('Order')}>Order Online</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" className="linking" onClick={() => this.scrollTo('Menu')}>Menu</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" className="linking" onClick={() => this.scrollTo('Hours')}>Hours & Location</NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <Link className="linking" to="/career" >Career</Link>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

function List(props) {
  const list = props.list;
  const listItems = list.map((item) =>
    <li key={item.toString()}>
      <em>{item}</em>
    </li>
  );
  return (
    <ul>{listItems}</ul>
  );
}

class ValCol extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'left': {
        icon: faShoppingCart,
        title: 'Ingredients',
        list: [
          'The highest quality ingredients',
          'Healthy',
          'Carefully selected fish',
          'Tested for quality and taste'
        ],
      },
      'mid': {
        icon: faUtensils,
        title: 'Chefs',
        list: [
          'Hours of Preparation Daily',
          'Classic Rolls',
          'Unique Creations',
          'Constantly Innovating'
        ],
      },
      'right': {
        icon: faUsers,
        title: 'Serving You',
        list: [
          'Efficient Carry Out',
          'Fast delivery',
          'Excellent atmosphere',
          'Attentive service'
        ],
      }
    }
  }

  render() {
    return (
      <Col lg='4' style={{ margin: '0 0 20px 0', }}>
        <Row>
          <Col xs='3'>
            <div className='fa-circle'>
              <div className='top'>
                <FontAwesomeIcon icon={this.state[this.props.id].icon} size="2x" />
              </div>
            </div>
          </Col>
          <Col >
            <h3>{this.state[this.props.id].title}</h3>
            <List list={this.state[this.props.id].list} />
          </Col>
        </Row>
      </Col>
    );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row>
        <Col className='hor-align'>
          <h1>{this.props.head}</h1>
          <hr className='colored' />
          <p>
            {this.props.desc}
          </p>
        </Col>
      </Row>
    );
  }
}

class Values extends React.Component {
  render() {
    return (
      <Element name='Values' id='Values' style={{ padding: '5% 0 5% 0' }}>
        <Container>
          <Header head='SUSHI' desc='We make the finest sushi using...' />

          <Row>
            <ValCol id='left' />
            <ValCol id='mid' />
            <ValCol id='right' />
          </Row>
        </Container>
      </Element>
    );
  }
}

class Menu extends React.Component {
  render() {
    return (
      <Element name='Menu' id='Menu' style={{ padding: '5% 0 5% 0' }}>
        <Container>
          <Header head='Menu' desc='Here is a selection of items from our menu. Please call for additional details.' />
          <Row style={{ 'textAlign': 'center' }}>
            <Col>
              <Fade left>
                <div style={{ float: 'left', width: '33.33%', padding: '5px' }}>
                  <img src={one} className="img-responsive" alt="" style={{ width: '100%' }} />
                </div>
                <div style={{ float: 'left', width: '33.33%', padding: '5px' }}>
                  <img src={two} className="img-responsive" alt="" style={{ width: '100%' }} />
                </div>
                <div style={{ float: 'left', width: '33.33%', padding: '5px' }}>
                  <img src={three} className="img-responsive" alt="" style={{ width: '100%' }} />
                </div>
              </Fade>
              <a href={menu} className='btn but-dark'>
                Sushi Hana Menu
              </a>
            </Col>
          </Row>
        </Container>
      </Element>
    );
  }
}
class Hours extends React.Component {
  render() {
    return (
      <Element name='Hours' id='Hours' style={{ padding: '5% 0 5% 0' }}>
        <Container>
          <Header head='Hours of Operations' desc='Call Us!' />
          <Row>
            <Col sm='6' md={{ size: 3, offset: 2 }}>
              <strong><h5>Phone Number</h5></strong>
              <p><a style={{ 'text-decoration': 'none' }} href='tel:1-410-377-4228'>(410) 377-4228</a></p>
              <strong><h5>Location</h5></strong>
              <p><a style={{ 'text-decoration': 'none' }} href="https://www.google.com/maps/search/?api=1&query=6080+Falls+Rd+Mt+Washington+MD+21209&query_place_id=ChIJrSxEhwcQyIkRBNiSkgpfTWM">6080 Falls Rd,<br /> Mt Washington,<br />MD 21209<br /></a></p>
            </Col>
            <Col sm='6' md={{ size: 3, offset: 2 }}>
              <strong><h5>Hours</h5></strong>
              <p>
                MON - THUR: 11AM - 10PM<br />
                FRI : 11AM - 11PM<br />
                SAT : 12PM - 11PM<br />
                SUN : 1PM - 10PM<br />
              </p>
            </Col>
          </Row>
        </Container>
      </Element>
    );
  }
}

const App = () => (

  <React.Fragment>
    <div className='top' style={{ backgroundColor: '#343a40', height: '0px', padding: '0px' }}> </div>
    <Navbars />
    <div className='para a'>
      <div className='logStyle'>
        <img src={profile} className="img-responsive img-centered log" alt="" />
      </div>
    </div>
    <Values />
    <Element name='Order' className='para b'>
      <div className='inside'>
        <strong>
          Our Sushi is a combination of quality ingredients, creativity, and attention to detail.
        </strong>
        <div>
          <a className='btn but-light medium-btn' href='https://www.doordash.com/store/158879/?utm_source=partner-link&utm_medium=website&utm_campaign=158879'>
            Delivery
          </a>
          <span style={{ paddingLeft: 10 }}>
            <a className='btn but-light medium-btn' href='https://catchasnack.com/restaurant/sushihana'>
              Pick Up
            </a>
          </span>
        </div>
      </div>
    </Element>
    <Menu />
    <div className='para c'>
      <div className='inside'>
        <strong>
          Check out our Sushi Hana Cheat Sheet for our Specialty Rolls.
        </strong>
        <div style={{ 'paddingTop': 20 }}>
          <a href={cheatSheet} className='btn but-light'>
            Cheat Sheet
          </a>
        </div>
      </div>
    </div>
    <Hours />
  </React.Fragment>
);
export default App;
